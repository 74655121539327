<template>
  <div v-frag>
    <div class="fourteen wide column">
      <h1>Mon compte</h1>
    </div>

    <div class="row">
      <div class="five wide column">
        <h4 class="ui horizontal divider header">
          PROFIL
        </h4>
        <div class="ui divided list">
          <div class="item">
            <div class="right floated content">
              <div class="description">
                <span v-if="user.username">{{ user.username }} </span>
              </div>
            </div>
            <div class="content">
              Nom d'utilisateur
            </div>
          </div>
          <div class="item">
            <div class="right floated content">
              <div class="description">
                {{ userFullname }}
              </div>
            </div>
            <div class="content">
              Nom complet
            </div>
          </div>
          <div class="item">
            <div class="right floated content">
              <div class="description">
                {{ user.email }}
              </div>
            </div>
            <div class="content">
              Adresse e-mail
            </div>
          </div>
          <div class="item">
            <div class="right floated content">
              <div class="description">
                {{ user.is_superuser ? "Oui" : "Non" }}
              </div>
            </div>
            <div class="content">
              Administrateur
            </div>
          </div>
        </div>
      </div>

      <div class="nine wide column">
        <h4 class="ui horizontal divider header">
          MES PROJETS
        </h4>

        <div class="ui divided items">
          <div
            v-for="project in availableProjects"
            :key="project.slug"
            class="item"
          >
            <div
              v-if="user_permissions[project.slug].can_view_project"
              v-frag
            >
              <div class="ui tiny image">
                <img
                  v-if="project.thumbnail"
                  class="ui small image"
                  :src="
                    project.thumbnail.includes('default')
                      ? require('@/assets/img/default.png')
                      : DJANGO_BASE_URL + project.thumbnail + refreshId()
                  "
                  height="200"
                >
              </div>
              <div class="middle aligned content">
                <router-link
                  :to="{
                    name: 'project_detail',
                    params: { slug: project.slug },
                  }"
                  class="header"
                >
                  {{ project.title }}
                </router-link>
                <div class="description">
                  <p>{{ project.description }}</p>
                </div>
                <div class="meta">
                  <span
                    class="right floated"
                  >Projet {{ project.moderation ? "" : "non" }} modéré</span>
                  <span>Niveau d'autorisation requis :
                    {{ project.access_level_pub_feature }}</span><br>
                  <span>
                    Mon niveau d'autorisation :
                    <span v-if="USER_LEVEL_PROJECTS && project">{{
                      USER_LEVEL_PROJECTS[project.slug]
                    }}</span>
                    <span v-if="user && user.is_administrator">{{
                      "+ Gestionnaire métier"
                    }}</span>
                  </span>
                </div>
                <div class="meta">
                  <span
                    class="right floated"
                    :data-tooltip="`Projet créé le ${project.created_on}`"
                  >
                    <i class="calendar icon" />&nbsp;{{ project.created_on }}
                  </span>
                  <span data-tooltip="Membres">
                    {{ project.nb_contributors }}&nbsp;<i class="user icon" />
                  </span>
                  <span data-tooltip="Signalements publiés">
                    {{ project.nb_published_features }}&nbsp;<i
                      class="map marker icon"
                    />
                  </span>
                  <span data-tooltip="Commentaires">
                    {{ project.nb_published_features_comments }}&nbsp;<i
                      class="comment icon"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="fourteen wide column">
        <div class="ui three stackable cards">
          <div class="red card">
            <div class="content">
              <div class="center aligned header">
                Mes dernières notifications reçues
              </div>
              <div class="center aligned description">
                <div class="ui relaxed list">
                  <div
                    v-for="item in events"
                    :key="item.id"
                    class="item"
                  >
                    <div class="content ellipsis nowrap">
                      <span v-if="item.event_type === 'create'">
                        <a
                          v-if="item.object_type === 'feature'"
                          :href="modifyUrl(item.related_feature.feature_url)"
                        >
                          Signalement créé
                        </a>
                        <a
                          v-else-if="item.object_type === 'comment'"
                          :href="modifyUrl(item.related_feature.feature_url)"
                        >
                          Commentaire créé
                        </a>
                        <a
                          v-else-if="item.object_type === 'attachment'"
                          :href="modifyUrl(item.related_feature.feature_url)"
                        >
                          Pièce jointe ajoutée
                        </a>
                        <a
                          v-else-if="item.object_type === 'project'"
                          :href="modifyUrl(item.project_url)"
                        >
                          Projet créé
                        </a>
                      </span>

                      <span v-else-if="item.event_type === 'update'">
                        <a
                          v-if="item.object_type === 'feature'"
                          :href="modifyUrl(item.related_feature.feature_url || item.project_url)"
                        >
                          Signalement mis à jour
                        </a>
                        <a
                          v-else-if="item.object_type === 'project'"
                          :href="modifyUrl(item.project_url)"
                        >à Projet mis à jour
                        </a>
                      </span>

                      <span v-else-if="item.event_type === 'delete'">
                        <span v-if="item.object_type === 'feature'">
                          Signalement supprimé({{ item.data.feature_title }})
                        </span>
                        <i v-else>Événement inconnu</i>
                      </span>

                      <span
                        v-if="item.object_type !== 'project'"
                        class="meta"
                      >
                        ({{ item.related_feature.title ?
                          item.related_feature.title :
                          'signalement supprimé'
                        }})
                      </span>

                      <div class="description">
                        <i>[ {{ item.created_on }}
                          <span v-if="user.is_authenticated">
                            , par {{ item.display_user }}
                          </span>
                          ]</i>
                      </div>
                    </div>
                  </div>
                  <i
                    v-if="!events || events.length === 0"
                  >Aucune notification pour le moment.</i>
                </div>
              </div>
            </div>
          </div>
          <div class="orange card">
            <div class="content">
              <div class="center aligned header">
                Mes derniers signalements
              </div>
              <div class="center aligned description">
                <div class="ui relaxed list">
                  <div
                    v-for="item in features"
                    :key="item.id"
                    class="item"
                  >
                    <div class="content">
                      <div>
                        <a
                          v-if="item.related_feature && item.related_feature.feature_url"
                          :href="modifyUrl(item.related_feature.feature_url)"
                        >{{ item.related_feature.title }}</a>
                        <span
                          v-else
                          class="meta"
                        ><del>{{ item.data.feature_title }}</del>&nbsp;(supprimé)</span>
                      </div>
                      <div class="description">
                        <i>[ {{ item.created_on }}
                          <span v-if="user.is_authenticated">
                            , par {{ item.display_user }}
                          </span>
                          ]</i>
                      </div>
                    </div>
                  </div>
                  <i
                    v-if="!features || features.length === 0"
                  >Aucun signalement pour le moment.</i>
                </div>
              </div>
            </div>
          </div>
          <div class="yellow card">
            <div class="content">
              <div class="center aligned header">
                Mes derniers commentaires
              </div>
              <div class="center aligned description">
                <div class="ui relaxed list">
                  <div
                    v-for="item in comments"
                    :key="item.id"
                    class="item"
                  >
                    <div class="content">
                      <div>
                        <a
                          :href="modifyUrl(item.related_feature.feature_url)"
                        >"{{ item.related_comment.comment }}"</a>
                      </div>
                      <div class="description">
                        <i>[ {{ item.created_on }}
                          <span v-if="user.is_authenticated">
                            , par {{ item.display_user }}
                          </span>
                          ]</i>
                      </div>
                    </div>
                  </div>
                  <i
                    v-if="!comments || comments.length === 0"
                  >Aucun commentaire pour le moment.</i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import frag from 'vue-frag';
import { mapState } from 'vuex';
import miscAPI from '@/services/misc-api';

export default {
  name: 'MyAccount',

  directives: {
    frag,
  },

  data() {
    return {
      events: [],
      features: [],
      comments: [],
    };
  },

  computed: {
    ...mapState([
      'user',
      'USER_LEVEL_PROJECTS',
      'user_permissions',
    ]),

    // todo : filter projects to user
    ...mapState('projects', [
      'projects'
    ]),

    DJANGO_BASE_URL() {
      return this.$store.state.configuration.VUE_APP_DJANGO_BASE;
    },

    userFullname() {
      if (this.user.first_name || this.user.last_name)
        return this.user.first_name + ' ' + this.user.last_name;
      return null;
    },

    isSharedProject() {
      return this.$route.path.includes('projet-partage');
    },

    availableProjects() {
      if (this.isSharedProject) {
        return this.projects.filter((el) => el.slug === this.$route.params.slug);
      }
      return this.projects;
    }
  },

  created(){
    this.getEvents();
  },

  methods: {
    refreshId() {
      return '?ver=' + Math.random();
    },

    getEvents(){
      miscAPI.getUserEvents(this.$route.params.slug)
        .then((data)=>{
          this.events = data.events;
          this.features = data.features;
          this.comments = data.comments;
        });
    },

    modifyUrl(url) {
      if (url && this.isSharedProject) {
        return url.replace('projet', 'projet-partage');
      }
      return url;
    }
  }
};
</script>